import { useCallback } from 'react';
import * as d3 from 'd3';
import { getCenter, getAnger } from './utils';

const getPointer = (angle) => {
  const absAngle = ((angle % 360) + 360) % 360;
  if (
    (absAngle > 337.5 && absAngle <= 360) ||
    (absAngle > 0 && absAngle <= 22.5) ||
    (absAngle > 157.5 && absAngle <= 202.5)
  ) {
    return 'row-resize';
  }
  if ((absAngle > 292.5 && absAngle <= 337.5) || (absAngle > 112.5 && absAngle <= 157.5)) {
    return 'nwse-resize';
  }
  if ((absAngle > 22.5 && absAngle <= 67.5) || (absAngle > 202.5 && absAngle <= 247.5)) {
    return 'nesw-resize';
  }
  if ((absAngle > 67.5 && absAngle <= 112.5) || (absAngle > 247.5 && absAngle <= 292.5)) {
    return 'ew-resize';
  }
  return 'default';
};

export default (drawTransform, onDragEnd, magnetPoints) =>
  useCallback(
    (selectAnchor, dragCorner, shape, subject) => {
      const center = shape ? getCenter(shape) : { x: 0, y: 0 };
      let rotationPoint = 0;
      const select = typeof selectAnchor === 'string' ? selectAnchor : selectAnchor?.current;
      const point = d3.select(select);
      rotationPoint = getAnger({ x: point.attr('cx') - center.x, y: center.y - point.attr('cy') });
      const pointer = getPointer(rotationPoint);

      const handleDragBottomRightCorner = d3
        .drag()
        .subject(() => subject)
        .on('drag', (e) => {
          if (shape) {
            const newShape = dragCorner({ shape, e, magnetPoints });
            if (document.body.style.cursor !== pointer) document.body.style.cursor = pointer;
            drawTransform(newShape);
          }
        })
        .on('end', () => {
          onDragEnd();
          document.body.style.cursor = 'default';
          const anchorY = d3.selectAll('.magnet-y');
          if (anchorY) {
            anchorY.attr('visibility', 'hidden');
          }
          const anchorX = d3.selectAll('.magnet-x');
          if (anchorX) {
            anchorX.attr('visibility', 'hidden');
          }
        });
      d3.select(select)
        .on('mouseover', () => {
          if (document.body.style.cursor !== pointer) document.body.style.cursor = pointer;
        })
        .on('mouseleave', () => {
          document.body.style.cursor = 'default';
        });
      handleDragBottomRightCorner(d3.select(select));
    },
    [drawTransform, magnetPoints, onDragEnd],
  );
