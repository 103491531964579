import React, { useCallback } from 'react';
import { faCirclePlus } from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDrop } from 'react-dnd';
import ReactFileReader from 'react-file-reader';
import { useAddNewPageToPhotoBook, useCountPages, useCountPagesInOneLetter, useMode, useToolbar } from 'hooks';
import { useSelector } from 'react-redux';
import './AddNewPhoto.scss';
import MozillaAndroidDoesNotSupportMultiFileSelect from 'constants/mozillaAndroidDoesNotSupportMultiFileSelect';
import { modes } from 'constants/index';
import classNames from 'classnames';
import { getPrintLimitMaxMinAndObjectsLengthSync } from 'components/common/PhotoLibrary/utils';
import { uploadAndWriteToStore } from 'components/common/PhotoLibrary/components/UploadButton';

const selectStyles = (isOver, canDrop) => {
  if (canDrop) {
    if (isOver) {
      return { background: '#0000004d' };
    }
    return { background: '#0000001a' };
  }
  return { background: 'transparent' };
};

const AddNewPhoto = React.memo(({ show, className }) => {
  const { openToolbar, openFooter } = useToolbar();

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'image',
    drop: () => ({
      allowedDropEffect: 'any',
      mode: modes.photoPrint,
    }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const mode = useMode();
  const isShow = useSelector((storeScene) => {
    const { printLimitMax, photoCount } = getPrintLimitMaxMinAndObjectsLengthSync(storeScene);
    if (printLimitMax > photoCount) return true;
    return false;
  });
  const handleFileUpload = React.useCallback((files) => {
    uploadAndWriteToStore(files);
  }, []);
  const numberOfPages = useCountPages();
  const countPagesInOneLetter = useCountPagesInOneLetter();
  const addNewPageToPhotoBook = useAddNewPageToPhotoBook(
    countPagesInOneLetter - ((numberOfPages - 1) % countPagesInOneLetter) || countPagesInOneLetter,
  );
  const style = React.useMemo(() => selectStyles(isOver, canDrop), [canDrop, isOver]);
  const onClick = useCallback(
    (e) => {
      if (mode === modes.photoBook) {
        addNewPageToPhotoBook();
        e.stopPropagation();
      } else {
        openToolbar('photos');
        openFooter('photos');
      }
    },
    [addNewPageToPhotoBook, mode, openFooter, openToolbar],
  );
  return show && isShow ? (
    <div className={classNames(className, 'add-photo')}>
      <ReactFileReader
        multipleFiles={!MozillaAndroidDoesNotSupportMultiFileSelect}
        fileTypes={['.png', '.jpg', '.jpeg']}
        handleFiles={handleFileUpload}
      >
        <div ref={drop} style={{ ...style }} onClick={onClick}>
          <FontAwesomeIcon.Memo icon={faCirclePlus} style={{ height: `100%`, width: `100%` }} />
        </div>
      </ReactFileReader>
      <div
        style={{
          width: '100%',
          textAlign: 'center',
        }}
      >
        Click to add more images
      </div>
    </div>
  ) : null;
});

export default AddNewPhoto;
