import { min } from 'lodash';
import store from 'store';
import SceneHelper from 'utils/SceneHelper';
import * as d3 from 'd3';
import { getCorners, pointProjection, distanceBetweenPoints, checkIsDraw } from './utils';

function findIntersectionPoints(magnetPoints, p1, p2) {
  const points = [];
  const { x: x1, y: y1 } = p1;
  const { x: x2, y: y2 } = p2;

  if (Math.abs(x1 - x2) > 0.0000005 && magnetPoints?.x) {
    const slope = (y2 - y1) / (x2 - x1);
    points.push(
      ...magnetPoints?.x?.map((x) => {
        const y = y1 + slope * (x - x1);
        return { x, y, magnetX: x };
      }),
    );
  }

  if (Math.abs(y1 - y2) > 0.0000005 && magnetPoints?.y) {
    const slope = (x2 - x1) / (y2 - y1);
    points.push(
      ...magnetPoints?.y?.map((y) => {
        const x = x1 + slope * (y - y1);
        return { x, y, magnetY: y };
      }),
    );
  }
  return points;
}

export const wrapDragFunction =
  (type) =>
  ({ getStartEnd, getNewShape }) =>
  ({ shape, e, magnetPoints }) => {
    const anchorY = d3.selectAll('.magnet-y');
    const anchorX = d3.selectAll('.magnet-x');
    const allowArea = SceneHelper.calcAllowAreaSync(store.getState(), type);
    const corners = getCorners(shape);
    const { startPoint, endPoint } = getStartEnd(corners);
    let projection = pointProjection(startPoint, endPoint, e);

    let newPosition = projection;
    const points = findIntersectionPoints(magnetPoints, startPoint, endPoint);
    let isMagnetX = false;
    let isMagnetY = false;
    points.forEach((point) => {
      const distance = distanceBetweenPoints(point, projection);
      if (distance < 5) {
        newPosition = point;
        if (anchorX && point.magnetX) {
          anchorX.attr('x1', point.magnetX);
          anchorX.attr('x2', point.magnetX);
          isMagnetX = true;
        }
        if (anchorY && point.magnetY) {
          anchorY.attr('y1', point.magnetY);
          anchorY.attr('y2', point.magnetY);
          isMagnetY = true;
        }
      }
    });
    projection = newPosition;
    if (isMagnetX) {
      anchorX.attr('visibility', 'visible');
    } else {
      anchorX.attr('visibility', 'hidden');
    }

    if (isMagnetY) {
      anchorY.attr('visibility', 'visible');
    } else {
      anchorY.attr('visibility', 'hidden');
    }

    const oldDistance = distanceBetweenPoints(startPoint, endPoint);
    let distance = distanceBetweenPoints(startPoint, projection);
    let scaleDistance = distance / oldDistance;
    const newShape = getNewShape({ projection, distance, oldDistance, shape, scaleDistance });

    // if the shape has decreased then return the new shape and do not make additional transformations
    if (scaleDistance <= 1) return newShape;

    // if you can draw this shape, return it
    const [isDraw, outside2] = checkIsDraw({ shape: newShape, allowArea });
    if (isDraw) return newShape;

    let minScale = 1;
    const { topRightPos, bottomLeftPos, topLeftPos, bottomRightPos } = corners;
    const [, outside] = checkIsDraw({ topRightPos, bottomLeftPos, topLeftPos, bottomRightPos, allowArea });
    Object.keys(outside2).forEach((name) => {
      if (outside2[name] > 0) {
        minScale = min([minScale, outside[name] / (outside[name] - outside2[name])]);
      }
    });
    const newProjection = {
      x: endPoint.x + (projection.x - endPoint.x) * minScale,
      y: endPoint.y + (projection.y - endPoint.y) * minScale,
    };
    distance = distanceBetweenPoints(startPoint, newProjection);
    scaleDistance = distance / oldDistance;
    return getNewShape({ projection: newProjection, distance, oldDistance, shape, scaleDistance });
  };
