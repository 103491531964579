import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDimensions, useMode, useEditorSizePx, useCenterEditedText, useAdmin, useSizeSVG } from 'hooks';
import useIsMobile from 'hooks/useIsMobile';
import { modes, shapeTypes } from 'constants/index';
import { usePageConfigContext } from 'context/pageConfig';
import Rect from './Rect';
import TextInput from './TextInput';
import 'react-toggle/style.css';
import TransformerContainer from './TransformerContainer';
import HelmetFonts from './HelmetFonts';
import EditorContent from './EditorContent';
import MaskClipPath from './MaskClipPath';
import Overlay from './Overlay';
import Underlay from './Underlay';
import PageNavigation from './PageNavigation';
import SvgContainer from './SvgContainer';
import c from './Editor.module.scss';

const sizeInPx = (size, sizeViewBox, sizePx = 1) => size * (sizeViewBox / sizePx);

export const useWidthHeight = (scaleFactor = 1) => {
  const pageConfig = usePageConfigContext();
  const [_width, _height, frame] = useEditorSizePx(pageConfig.pageId);
  return useMemo(
    () => ({
      width: (_width + 2 * frame) * scaleFactor,
      height: (_height + 2 * frame) * scaleFactor,
    }),
    [_height, _width, frame, scaleFactor],
  );
};

const FrameSvg = ({ scaleFactor }) => {
  const { width } = useWidthHeight(scaleFactor);
  const dimensions = useDimensions();
  const { width: widthSVG, height: heightSVG } = useSizeSVG();
  const scaleText = useMemo(() => width / widthSVG, [width, widthSVG]);
  const mode = useMode();
  return (
    <SvgContainer>
      <defs>
        <mask id="mask-top">
          <svg
            width="100%"
            x={-(dimensions.originalFrame - dimensions.frame)}
            y={-(dimensions.originalFrame - dimensions.frame)}
            height="100%"
            viewBox={`0 0 ${widthSVG} ${heightSVG}`}
          >
            <rect
              x={dimensions.originalFrame}
              y={0}
              width={dimensions.width}
              height={dimensions.originalFrame}
              fill="#fff"
            />
          </svg>
        </mask>
        <mask id="mask-right">
          <svg
            width="100%"
            height="100%"
            x={-(dimensions.originalFrame - dimensions.frame)}
            y={-(dimensions.originalFrame - dimensions.frame)}
            viewBox={`0 0 ${widthSVG} ${heightSVG}`}
          >
            <rect
              x={dimensions.originalFrame + dimensions.width}
              y={dimensions.originalFrame}
              width={dimensions.originalFrame}
              height={dimensions.height}
              fill="#fff"
            />
          </svg>
        </mask>
      </defs>
      <Underlay />
      {mode === modes.canvas ? (
        <>
          <g
            transform={`translate(0, ${dimensions.frame - 1}) skewX(-45) translate(0, -${dimensions.frame})`}
            mask="url(#mask-top)"
          >
            <EditorContent
              scaleText={scaleText}
              shapeTypes={[shapeTypes.layout, shapeTypes.dropZone, shapeTypes.background]}
              onlySvg
              originalSrc={false}
              showEmptyPhoto
            />
            <linearGradient id="topShadow" gradientTransform="rotate(75)">
              <stop offset="5%" stopColor="grey" stopOpacity="0.6" />
              <stop offset="95%" stopColor="grey" stopOpacity="0.1" />
            </linearGradient>
            <rect
              x={dimensions.frame}
              y={0}
              width={dimensions.width}
              height={dimensions.frame}
              opacity="0.6"
              fill="url('#topShadow')"
            />
          </g>
          <g
            transform={`translate(${dimensions.frame + dimensions.width + 1}, ${
              dimensions.frame
            }) skewY(-45) translate(-${dimensions.frame + dimensions.width}, -${dimensions.frame})`}
            mask="url(#mask-right)"
          >
            <EditorContent
              scaleText={scaleText}
              shapeTypes={[shapeTypes.layout, shapeTypes.dropZone, shapeTypes.background]}
              onlySvg
              originalSrc={false}
              showEmptyPhoto
            />
            <linearGradient id="myGradient2" gradientTransform="rotate(30)">
              <stop offset="5%" stopColor="grey" stopOpacity="0.1" />
              <stop offset="95%" stopColor="grey" stopOpacity="0.6" />
            </linearGradient>
            <rect
              x={dimensions.width + dimensions.frame}
              y={dimensions.frame}
              width={dimensions.frame}
              height={dimensions.height}
              opacity="0.6"
              fill="url('#myGradient2')"
            />
          </g>
        </>
      ) : null}
    </SvgContainer>
  );
};

const Editor = ({ scaleFactor = 1 }) => {
  const { width, height } = useWidthHeight(scaleFactor);
  const isMobile = useIsMobile();
  const dimensions = useDimensions();
  const isAdmin = useAdmin();
  const pageConfig = usePageConfigContext();
  const { width: widthSVG, height: heightSVG } = useSizeSVG();
  const currentSizeInPx = useCallback((size) => sizeInPx(size, widthSVG, width), [width, widthSVG]);
  const contentOffsetY = useSelector(
    ({
      scene: {
        config: { contentOffsetY },
      },
    }) => contentOffsetY,
  );
  const scaleText = useMemo(() => width / widthSVG, [width, widthSVG]);
  const mode = useMode();
  const centerText = useCenterEditedText();
  const transform =
    isMobile && centerText
      ? `scale(1.25) translate(${(widthSVG / 2 - centerText.x) * scaleText}px, ${
          (heightSVG / 2 - centerText.y) * scaleText
        }px)`
      : '';
  const transformEditorContainer = useMemo(
    () => `translate(0, ${contentOffsetY * heightSVG})`,
    [contentOffsetY, heightSVG],
  );
  const transitionDuration = isMobile && centerText ? '300ms' : 'none';
  return (
    <>
      <HelmetFonts />
      <div
        style={{
          width: `${width}px`,
          height: `${height}px`,
          transform,
          transitionDuration,
        }}
        className={c.editorContainer}
      >
        <PageNavigation />
        <FrameSvg scaleFactor={scaleFactor} />
        <SvgContainer style={{ willChange: 'transform, top, left, content' }}>
          <MaskClipPath />
          <g className="editorContainer" transform={transformEditorContainer}>
            <g mask={`url(#mask-clip-path${pageConfig?.pageId})`}>
              <EditorContent scaleText={scaleText} />
            </g>
            {mode === modes.poster || isAdmin ? (
              <Rect
                x={dimensions.halfBleed + dimensions.frame}
                y={dimensions.halfBleed + dimensions.frame}
                width={dimensions.width - 2 * dimensions.halfBleed}
                height={dimensions.height - 2 * dimensions.halfBleed}
                strokeWidth={currentSizeInPx(1)}
                strokeDasharray={`${currentSizeInPx(10)}, ${currentSizeInPx(10)}`}
                fill="#ffffff00"
                stroke="#ffffff"
              />
            ) : null}
          </g>
          <line
            id="magnet-x"
            className="magnet-x"
            x1={dimensions.originalFrame}
            y1={dimensions.height + dimensions.originalFrame}
            x2={dimensions.originalFrame}
            y2={dimensions.originalFrame}
            stroke="black"
            strokeLinecap="round"
            strokeWidth="1"
            strokeDasharray="5,5"
            fill="none"
            visibility="hidden"
          />
          <line
            className="magnet-y"
            x1={dimensions.originalFrame}
            y1={dimensions.originalFrame}
            x2={dimensions.width + dimensions.originalFrame}
            y2={dimensions.originalFrame}
            stroke="black"
            strokeLinecap="round"
            strokeWidth="1"
            strokeDasharray="5,5"
            fill="none"
            visibility="hidden"
          />
          <g className="transformerContainer" transform={transformEditorContainer}>
            <TransformerContainer />
          </g>
          <g className={c.textInput}>
            <TextInput />
          </g>
        </SvgContainer>
        <Overlay />
      </div>
    </>
  );
};

export default Editor;
